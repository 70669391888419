import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const website_url = 'lbyg.lankarama.com.au';

export const textOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <p className="text-xl my-10 leading-8 tracking-normal">{children}</p>
    ),
    [BLOCKS.HEADING_1]: (_, children) => (
      <h2 className="text-4xl font-bold mt-8 mb-4 font-heading">{children}</h2>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <h2 className="text-3xl font-bold mt-8 mb-4 font-heading">{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <h3 className="text-2xl font-bold mt-8 mb-4 font-heading">{children}</h3>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <h4 className="text-xl font-bold mt-8 mb-4 font-heading">{children}</h4>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <h5 className="text-xl font-bold mt-8 mb-4 font-heading">{children}</h5>
    ),
    [BLOCKS.LIST_ITEM]: (_, children) => {
      const strippedChildren = children.map((child) =>
        Object.assign({}, child, {
          props: { className: 'text-xl', children: child.props.children },
        })
      );
      return <li className="list-disc ml-4">{strippedChildren}</li>;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, _) => {
      return node.data.target.description ? (
        <figure className="my-4">
          <img
            className="mb-2 rounded-lg"
            src={node.data.target.fixed.src}
            alt={'title'}
          />
          <figcaption className="text-gray-500 font-semibold italic text-center">
            {node.data.target.description}
          </figcaption>
        </figure>
      ) : (
        <img
          className="my-4 rounded-lg"
          src={node.data.target.fixed.src}
          alt={'title'}
        />
      );
    },
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a
        href={data.uri}
        target={`${data.uri.includes(website_url) ? '_self' : '_blank'}`}
        rel={`${data.uri.includes(website_url) ? '' : 'noopener noreferrer'}`}
        className="text-secondary font-semibold hover:underline visited:text-purple-600"
      >
        {children}
      </a>
    ),
  },
  renderText: (text) => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

export const referencesOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => {
      const parsedReference = children.map((child) => {
        if (child.type === 'a') {
          return Object.assign({}, child, {
            props: { className: 'break-all underline', ...child.props },
          });
        }
        return child;
      });
      return (
        <p className="text-md my-4 leading-8 tracking-normal whitespace-pre-wrap ">
          {parsedReference}
        </p>
      );
    },
  },
  renderText: (text) => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};
